// 
import React, {
  useEffect, useState,
} from 'react';
import { atom, useSetRecoilState } from 'recoil';
import { useMediaQuery } from 'react-responsive';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Helmet } from 'react-helmet';
import {
  FacebookShareButton,
  TwitterShareButton,
} from 'react-share';
import { Mobile, PC } from '../../components/layout/mediaQuery';
import '../../styles/colors.scss';
import {
  btnArray1,
  btnArray2,
  btnArray3,
  btnArray4,
  btnArray5,
  btnArray6,
  btnArray7,
  btnArray8,
  btnArray9,
  btnArray10,
  btnArray11,
  btnArray12,
} from '../../contents/muckjjang-button-array';
import { KAKAO_CS_URL } from '../../constants/urls';
import NotFoundPage from '../404';
import twitterLogo from '../../assets/images/product/twitter.png';
import facebookLogo from '../../assets/images/product/facebook.png';
import linkLogo from '../../assets/images/product/link.png';
import SvgLogoText from '../../assets/images/brand/logo-text';
import Kakao from '../../Kakao';

export const IsGame = atom({
  key: 'IsGame',
  default: false,
});

function Game() {
  const setIsGame = useSetRecoilState(IsGame);
  useEffect(() => {
    setIsGame(true);
  }, []);

  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const [pageNum, setPageNum] = useState(0);
  const [clicked, setClicked] = useState<boolean>(false);
  const [btnArray, setBtnArray] = useState([]);
  const [score, setScore] = useState(0); // 점수


  useEffect(() => {
    console.log(pageNum);
    switch (pageNum) {
      case 1: setBtnArray(btnArray1); break;
      case 2: setBtnArray(btnArray2); break;
      case 3: setBtnArray(btnArray3); break;
      case 4: setBtnArray(btnArray4); break;
      case 5: setBtnArray(btnArray5); break;
      case 6: setBtnArray(btnArray6); break;
      case 7: setBtnArray(btnArray7); break;
      case 8: setBtnArray(btnArray8); break;
      case 9: setBtnArray(btnArray9); break;
      case 10: setBtnArray(btnArray10); break;
      case 11: setBtnArray(btnArray11); break;
      case 12: setBtnArray(btnArray12); break;

      default: setBtnArray(btnArray8); break;
    }
  }, [pageNum]);

  function pageNumPlus() {
    if (pageNum < 12) {
      setPageNum(pageNum + 1);
    }
  }

  // 뒤로가기 만들어야 할 듯 말하기
  function pageNumMinus() {
    setPageNum(pageNum - 1);
  }

  // 선택했을 떄, 다음으로 넘어감일단 점수만 더해주면 되는데
  function onClick(e: React.MouseEvent<HTMLButtonElement>) {
    pageNumPlus();
    setClicked(!clicked);
    const { value } = e.currentTarget;
    console.log(value);
  }

  function Button() {
    return (
      <>
        {btnArray.map((item: any) => (
          <button
            key={item.value}
            type="button"
            id={item.id.toString()}
            className="button_inActive"
            name={item.value}
            value={item.value}
            onClick={(e) => onClick(e)}
          >
            {item.answer}
          </button>
        ))}
      </>
    );
  }

  function Page() {
    return (
      <div style={{ height: '100vh', marginTop: '50px' }}>
        <div className="progress_text">
          {pageNum}
          /12
        </div>
        <div className="progress_bar">
          <div className="progress" />
        </div>
        <div style={{ paddingLeft: '8px' }}>
          <div className="icon">{btnArray[0].icon}</div>
          <div className="question">
            {btnArray[0].question}
          </div>
        </div>
        <div className="button_container">
          <Button />
        </div>
      </div>
    );
  }

  function Page0() { // 시작 페이지
    return (
      <NotFoundPage />
      // <div style={{ height: '100vh' }}>
      //   <div style={{ paddingLeft: '8px' }}>
      //     <div className="icon">🤔</div>
      //     <div className="question">먹짱 테스트</div>
      //     <div className="sub">밀리언이 알려드릴게요</div>
      //   </div>
      //   <div className="button_container" style={{ marginTop: '38px' }}>
      //     <button type="button" onClick={pageNumPlus}>시작하기</button>
      //   </div>
      // </div>
    );
  }

  // 결과창
  return (
    <>
      {/* <Helmet>
        <title>오늘 뭐 먹지? 🤔</title>
        <meta name="description" content="밀리언이 추천해줄게!" />
        <meta property="og:title" content="오늘 뭐 먹지? 🤔" />
        <meta property="og:description" content="밀리언이 추천해줄게!" />
        <meta property="og:url" content="https://mealion.kr/game/what-to-eat" />
        <meta property="og:image" content="https://mealion.kr/static/game-bc6a095b844abcd9416babc23577acc8.png" />
      </Helmet>
      <Mobile>
        <div style={{
          height: pageNum !== 6 && '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          background: 'var(--color-light)',
        }}
        >
          <div className="center">
            <div className="container" style={{ width: '90%' }}>
              {pageNum === 0 && <Page0 />}
              {pageNum > 0 && <Page />}
            </div>
          </div>
        </div>
      </Mobile>
      <PC>
        <NotFoundPage />
      </PC>
      <style jsx global>
        {`
             .center {
               background: var(--color-light);
               width: 100%;
               height: 100%;
               align-items: center;
               justify-content: center;
               display: flex;
               flex-direction: column;
               padding-top: 0;
             }
             .container {
               background: var(--color-light);
               text-align: left;
               width: ${isMobile ? '90%' : '60%'};
               align-items: center;
               overflow: hidden;
              }
              
              .progress_bar {
                background: --color-gray-input;
                width: 100%;
                height: 21px;
                border-radius: 16px;
              }
              .progress {
                background: #0080FF;
                width: ${8.3 * pageNum}%;
                height: 100%;
                border-top-left-radius: 16px;
                border-bottom-left-radius: 16px;
                border-top-right-radius: ${pageNum === 12 ? '16px' : 0};
                border-bottom-right-radius: ${pageNum === 12 ? '16px' : 0};
              }
              .progress_text {
                text-align: right;
                color: #6D7780;
                font-size: 2vh;
                line-height: 2vh;
                font-weight: 400;
                margin-bottom: 5px;
              }
             .button_container {
               display: flex;
               flex-direction: column;
               gap: 2vh;
               margin-top: 13vh; //116
             }
             .button_inActive {
               white-space: pre-wrap;
               margin: 0;
               height: 15vh;
               border: none;
               background: --color-gray-input;
               border-radius: 16px;
               color: #35414C;
               font-weight: 500;
               line-height: 156%;
               letter-spacing: -0.33px;
               text-align: center;
               font-size: 18px;
            }

             .result_text_title {
               color: #35414C;
               font-weight: 700;
               font-size: 3.1vh;
               letter-spacing: -0.5%;
               margin-top: 40px;
               margin-bottom: 1vh;
  
             }
             .result_text_sub {
               color: #6D7780;
               font-size: 2.2vh;
               font-weight: 500;
               letter-spacing: -0.33px;
             }
             .result_text {
               color: #6D7780;
               font-size: 2.5vh;
               font-weight: 500;
               margin-bottom: 16px;
             }
             .result_text_with_logo {
               color: var(--color-light-gray-text);
               font-size: 2vh;
               line-height: 3.6vh;
               font-weight: 500;
             }
  
             .result_text_share {
               color: #35414c;
               font-weight: 500;
               font-size: 2vh;
               letter-spacing: -0.33px;
             }
             .result_div {
               display: flex;
               align-items: center;
               justify-content: center;
               flex-direction: column;
               flex: 1;
               height: 16.5vh;
               min-width: 17vh;
               font-size: 2.7vh;
               font-weight: 700;
               line-height:2.8vh;
               text-align: center;
               color: var(--color-light);
               border-radius: 16px;
               background: #0080FF;
             }
  
             button.sub_button {
               white-space: no-wrap;
               background: var(--color-primary);
               width: ${isMobile ? '100%' : '30%'};
               height: 8vh;
               border-radius: 16px;
               border: none;
               color: var(--color-light);
               font-weight: 700;
               font-size: 16px;
               line-height: 19px;
               margin-top: 1.7vh;
               cursor: pointer;
             }
             .icon {
               font-weight: 700;
               font-size: 5vh;
               text-align: center;
               line-height: 5vh;
               margin-top: 10vh;
               margin-bottom: 6vh;
             }
             .question {
               font-weight: 700;
               text-align: center;
               font-size: 3.3vh;
               line-height: 130%;
               color: #35414C;
               margin: 0;
               white-space: pre-wrap;
             }
             .sub {
               font-weight: 400;
               font-size: 2vh;
               line-height: 2vh;
               color: #6D7780;
               white-space: no-wrap;
             }
             .feedback {
               margin-bottom: 20px;
               color: var(--color-light-gray-text);
               font-size: 12px;
               line-height: 12px;
               letter-spacing: -0.33px;
               text-align: center;
               font-weight: 500;
             }
             .feedback strong {
               color: #0080FF;
               font-weight: 500;
             }
             button:disabled {
              background: transparent;
             }
            .gray_container {
              margin-top: 2vh;
              width: 100%;
              background: --color-gray-input;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              text-align: center;
            }
            }
          `}
      </style> */}
    </>
  );
}
export default Game;

// isclicked 없어도 되나
export const btnArray1 = [
  {
    id: 0,
    icon: '🍽',
    question: '외식을 하려고 한다.\n선정 기준은',
    answer: '메뉴, 위치, 분위기 등\n복합적으로 고려해서 선정한다.',
    score: 0,
  },
  {
    id: 1,
    icon: '🍽',
    question: '외식을 하려고 한다\n선정 기준은?',
    answer: '무조건 맛이 1등이지~\n조금 비싸고 멀어도 맛이 중요하다.',
    score: 1,
  },
];
// score 추가해야함
export const btnArray2 = [
  {
    id: 0,
    icon: '🍚',
    question: '평소 식사에 대한\n생각은?',
    answer: '그냥 저냥~ 먹어야 하니깐 먹는다',
  },
  {
    id: 1,
    icon: '🍚',
    question: '평소 식사에 대한\n생각은?',
    answer: '무슨소리, 식사는 나의 즐거움',
  },
];

export const btnArray3 = [
  {
    id: 0,
    icon: '🍡',
    question: '떡볶이 먹을 때\n드는 생각은?',
    answer: '다 먹고 국물에 볶음밥 해먹어야지 ㅎㅎ\n그리고 후식으로 크로플 먹으면 딱이겠다.',

  },
  {
    id: 1,
    icon: '🍡',
    question: '떡볶이 먹을 때\n드는 생각은?',
    answer: '음 맛있다 떡볶이 냠냠 ',
  },
];

export const btnArray4 = [
  {
    id: 0,
    icon: '🍧',
    question: '새로 나온 신상빙수가\nSNS에서 핫하다.',
    answer: '이런 게 있구나~',
  },
  {
    id: 1,
    icon: '🍧',
    question: '새로 나온 신상빙수가\nSNS에서 핫하다.',
    answer: '당장 먹어야 해. 친구와 뿌시러간다.',
  },
];

export const btnArray5 = [
  {
    id: 0,
    icon: '🤔',
    question: '밥을 먹기로 한 친구들과\n먹고 싶은 메뉴가 다르다.',
    answer: '어쩔 수 없지~\n너희가 먹고 싶은 거 먹으러 가자!',
  },
  {
    id: 1,
    icon: '🤔',
    question: '밥을 먹기로 한 친구들과\n먹고 싶은 메뉴가 다르다.',
    answer: '그래도 한 번 더 어필해본다.\n이것도 진짜 맛있는데!',  
  },
];

export const btnArray6 = [
  {
    id: 0,
    icon: '🌮',
    question: '이국적인 메뉴를 파는\n식당을 방문했다.',
    answer: '익숙한 메뉴를 고른다!',
  },
  {
    id: 1,
    icon: '🌮',
    question: '이국적인 메뉴를 파는\n식당을 방문했다.',
    answer: '음식은 도전이야.\n낯선 메뉴를 골라 모험한다.',
  },
];

export const btnArray7 = [
  {
    id: 0,
    icon: '📱',
    question: 'SNS 유명 맛집을 방문했다.\n세상에, 줄이 삼만리다. ',
    answer: '아쉽지만 포기하고 다음 기회를 노린다.',
  },
  {
    id: 1,
    icon: '📱',
    question: 'SNS 유명 맛집을 방문했다.\n세상에, 줄이 삼만리다. ',
    answer: '기왕 왔는데 돌아갈 순 없지,\n게다가 나만 못먹으면 자존심이 상한다구!',
  },
];

export const btnArray8 = [
  {
    id: 0,
    icon: '😵',
    question: '일이 바빠 끼니를 놓쳤다.\n배에서 꼬르륵 소리가 난다. ',
    answer: '당장 뭐라도 먹겠어.\n먹고 살자고 하는 건데~  ',
  },
  {
    id: 1,
    icon: '😵',
    question: '일이 바빠 끼니를 놓쳤다.\n배에서 꼬르륵 소리가 난다. ',
    answer: '기왕 이렇게 된 거\n일 다 하고 이따가 먹지 뭐...',
  },
];

export const btnArray9 = [
  {
    id: 0,
    icon: '🎂',
    question: '오늘은 신나는 내 생일!\n더 받고싶은 선물은?',
    answer: '평소 갖고 싶었던 물건 ',
  },
  {
    id: 1,
    icon: '🎂',
    question: '오늘은 신나는 내 생일!\n더 받고싶은 선물은?',
    answer: '유명 셰프의 코스요리',
  },
];

export const btnArray10 = [
  {
    id: 0,
    icon: '😲',
    question: '처음 가본 식당이\n숨겨진 맛집이었다.',
    answer: '당장 SNS에 올려서\n이 맛있음을 널리널리 알린다.',
  },
  {
    id: 1,
    icon: '😲',
    question: '처음 가본 식당이\n숨겨진 맛집이었다.',
    answer: '오~ 여기 맛있다~ 하고 만다.',
  },
];
export const btnArray11 = [
  {
    id: 0,
    icon: '😲',
    question: '다이어트를 결심하고\n샐러드를 사서 집에 갔다.\n하필 엄마가 삼겹살을 굽고있다. ',
    answer: '그래도 난 다이어트를 해야하니까\n샐러드를 먹는다.',
  },
  {
    id: 1,
    icon: '😲',
    question: '다이어트를 결심하고\n샐러드를 사서 집에 갔다.\n하필 엄마가 삼겹살을 굽고있다. ',
    answer: '다이어트? 내일부터ㅋ\n눈 앞의 삼겹살을 어떻게 참아?',
  },
];
export const btnArray12 = [
  {
    id: 0,
    icon: '😋',
    answer: '“기다리고 있었습니다. 제대로 모시겠습니다.”\n취향, 동행자, 분위기 모든 것을 고려해서\n리스트를 보내준다.',
    question: '친구가 맛집 추천을\n부탁하는데...',
  },
  {
    id: 1,
    icon: '😋',
    question: '친구가 맛집 추천을 부탁하는데...',
    answer: '어… 그 근처에 여기 맛있대~ 대충 알려준다.',
  },
];
